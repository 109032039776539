import './App.css';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import {
    defaultTheme,   // Default theme
    darkTheme,      // Dark theme
} from '@ant-design/compatible';
import {ConfigProvider, Layout} from "antd";
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import de from 'antd/locale/de_DE'

import {StreamCalendar} from "./calendar/StreamCalendar";
import {HeaderNav} from "./shared/HeaderNav";
import {DrunkCompany} from "./drunkCompany/DrunkCompany";
import {AppLayout} from "./shared/AppLayout";
import {MCBuildContest} from "./mcBuildContest/MCBuildContest";


dayjs.locale('de');

const router = createBrowserRouter([
    {
        element: <AppLayout />,
        children: [
            {
                index: true,
                element: <StreamCalendar/>,
            },
            {
                path: "/drunkcompany",
                element: <DrunkCompany/>
            },
            {
                path: "/mcbuildcontest",
                element: <MCBuildContest />
            }
        ]
    }

]);

function App() {
    return (
        <ConfigProvider theme={darkTheme} locale={de}>
            <Layout>
                <RouterProvider router={router}/>
            </Layout>
        </ConfigProvider>
    );
}

export default App;
