import {Calendar, Flex, Layout, Tag, Typography} from "antd";
import de from 'antd/locale/de_DE'
import dayjs from "dayjs";
import "./streamCalendar.css";
import {Link} from "react-router-dom";


export const StreamCalendar = () => {
    const getListData = (value) => {
        let listData = []; // Specify the type of listData
        if (value.month() === 9) {
            switch (value.date()) {
                case 2:
                    listData = [
                        {
                            type: 'success',
                            content: 'Drunk Company',
                        },
                        {
                            type: 'warning',
                            content: 'Mitspieler gesucht*',
                        }
                    ];
                    break;
                case 3:
                    listData = [
                        {
                            type: 'processing',
                            content: 'Feiertag'
                        },
                        {
                            type: 'success',
                            content: 'Minecraft Building Contest Start',
                        }
                    ];
                    break;
                case 4:
                    listData = [
                        {
                            type: 'error',
                            content: 'Urlaub',
                        }
                    ];
                    break;
                case 5:
                    listData = [
                        {
                            type: 'error',
                            content: 'Urlaub',
                        }
                    ];
                    break;
                case 6:
                    listData = [
                        {
                            type: 'error',
                            content: 'Urlaub',
                        }
                    ];
                    break;
                case 7:
                    listData = [
                        {
                            type: 'error',
                            content: 'Urlaub',
                        }
                    ];
                    break;
                case 8:
                    listData = [
                        {
                            type: 'error',
                            content: 'Urlaub',
                        }
                    ];
                    break;
                case 9:
                    listData = [
                        {
                            type: 'error',
                            content: 'Urlaub',
                        }
                    ];
                    break;
                case 10:
                    listData = [
                        {
                            type: 'error',
                            content: 'Urlaub',
                        }
                    ];
                    break;
                case 11:
                    listData = [
                        {
                            type: 'success',
                            content: 'L4D',
                        }
                    ];
                    break;
                case 12:
                    listData = [
                        {
                            type: 'success',
                            content: 'L4D',
                        }
                    ];
                    break;
                case 13:
                    listData = [
                        {
                            type: 'success',
                            content: 'DBD',
                        },
                        {
                            type: 'warning',
                            content: 'Mitspieler gesucht*',
                        }
                    ];
                    break;
                case 16:
                    listData = [
                        {
                            type: 'success',
                            content: 'Chill Stream',
                        }
                    ];
                    break;
                case 18:
                    listData = [
                        {
                            type: 'success',
                            content: 'L4D 2',
                        }
                    ];
                    break;
                case 19:
                    listData = [
                        {
                            type: 'success',
                            content: 'L4D 2',
                        }
                    ];
                    break;
                case 20:
                    listData = [
                        {
                            type: 'success',
                            content: 'DBD',
                        },
                        {
                            type: 'warning',
                            content: 'Mitspieler gesucht*',
                        }
                    ];
                    break;
                case 23:
                    listData = [
                        {
                            type: 'success',
                            content: 'Chill Stream',
                        }
                    ];
                    break;
                case 25:
                    listData = [
                        {
                            type: 'success',
                            content: '???',
                        }
                    ];
                    break;
                case 27:
                    listData = [
                        {
                            type: 'success',
                            content: 'Minecraft Building Contest Ende',
                        }
                    ];
                    break;
                case 30:
                    listData = [
                        {
                            type: 'success',
                            content: 'Drunk Company',
                        },
                        {
                            type: 'warning',
                            content: 'Mitspieler gesucht*',
                        }
                    ];
                    break;
                case 31:
                    listData = [
                        {
                            type: 'processing',
                            content: 'Feiertag'
                        },
                        {
                            type: 'success',
                            content: '???',
                        }
                    ];
                    break;
                default:
            }
        }

        return listData || [];
    };

    const dateCellRender = (value) => {
        const listData = getListData(value);
        return (
            <ul className="events">
                {listData.map((item) => (
                    <Flex gap="small" wrap>
                        <Tag color={item.type}>{item.content}</Tag>
                    </Flex>
                ))}
            </ul>
        );
    };

    return (
        <>
            <Calendar cellRender={dateCellRender} locale={de} defaultValue={dayjs('2024-10-1 12:00')}
                      className={"hideHeader"}/>
            <div className={"bottomContainer"}>
                <Link to={"/mcBuildContest"}>
                    <div className={"bottomBox"}>
                        <Tag color="success" style={{marginBottom: "0.5rem"}}>Minecraft Building Contest</Tag>
                        <Typography>Der Contest wird auf meinem Server stattfinden. Hier wird eine Fläche von 100x100
                            Blöcken
                            auf dem Creative Server bereitgestellt.<br/>
                            Wer innerhalb der Zeit das Beste Adventure/ Puzzle/ JnR baut, bekommt einen
                            Preis.</Typography>
                    </div>
                </Link>
                <Link to={"/drunkcompany"}>
                    <div className={"bottomBox"}>
                        <Tag color="success" style={{marginBottom: "0.5rem"}}>Drunk Company</Tag>
                        <Typography>Drunk Company ist ein Mix aus Lethal Company und Trinkspiel. Je nachdem was
                            passiert,
                            muss getrunken werden.<br/>
                            Natürlich nur jeder soviel er kann und selbstverständlich ist der Stream
                            18+</Typography>
                    </div>
                </Link>
                <div className={"bottomBox"}>
                    <Tag color="warning" style={{marginBottom: "0.5rem"}}>Mitspieler gesucht</Tag>
                    <Typography>Gilt hauptsächlich für Leute mit denen ich schon gespielt habe.<br/>
                        Einfach direkt bei mir melden wenn interesse besteht</Typography>
                </div>
            </div>
        </>
    )
}
