import {Col, Divider, Row, Typography} from 'antd';

import funnyImage from "../assets/lethalCompanyMemeImage.png";
import "../shared/events.css";
import "./drunkCompany.css";

const {Title, Paragraph, Text, Link} = Typography;

export const DrunkCompany = () => {
    return (
        <>
            <div className="container-dcom text">
                <div className={"foreground"}>
                    <Typography>
                        <Title>Drunk Company</Title>
                        <Paragraph>Drunk Company ist ein Mix aus Lethal Company und Trinkspiel. Je nachdem was passiert,
                            muss getrunken werden.<br/>
                            Natürlich nur jeder soviel er kann und selbstverständlich ist der Stream 18+.<br/>
                        Thunderstore Code: 01924dec-060b-d4b4-84c1-1cb1f75516bc</Paragraph>

                    </Typography>
                    <Row>
                        <Col span={2}>

                        </Col>
                        <Col span={6}>
                            <Typography>
                                <Title level={2} className={"regelnHeader"}>Regeln</Title>
                            </Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={3}>

                        </Col>
                        <Col span={6}>
                            <Typography>
                                <Title level={4}>Einen Schluck</Title>
                                <ul>
                                    <li>beim Landen</li>
                                    <li>Tod durch Monster (außer Company und Masked)</li>
                                    <li>Mitspieler umbringen</li>
                                </ul>
                                <Title level={4}>Drei Schlücke</Title>
                                <ul>
                                    <li>Tod durch:
                                        <li>Fallschaden</li>
                                        <li>Runterfallen</li>
                                        <li>Landminen</li>
                                        <li>Turrets</li>
                                        <li>Blitzeinschlag</li>
                                        <li>Treibsand</li>
                                        <li>Deckenfalle</li>
                                    </li>
                                </ul>
                            </Typography>
                        </Col>
                        <Col span={6}>
                            <Typography>
                                <Title level={4}>Drei Schlücke verteilen</Title>
                                <ul>
                                    <li>für das Töten eines Monsters</li>
                                    <li>Lootbug nimmt dein Geschenk an</li>
                                    <li>Bienennest erfolgreich looten</li>
                                </ul>
                            </Typography>
                        </Col>
                        <Col span={6}>
                            <Typography>
                                <Title level={4}>Austrinken</Title>
                                <ul>
                                    <li>Tod durch
                                        <li>Company Monster</li>
                                        <li>Masked</li>
                                    </li>
                                </ul>
                                <Title level={4}>Alle austrinken</Title>
                                <ul>
                                    <li>Quote nicht erreicht</li>
                                    <li>alle sterben</li>
                                    <li>Jemand wird zurückgelassen</li>
                                </ul>
                            </Typography>
                        </Col>
                        <Col span={3}>

                        </Col>
                    </Row>
                </div>
            </div>
            <a href={"https://x.com/theFireseal/status/1743439237905838290/photo/1"} target={"_blank"} rel="noreferrer"
               className={"funnyImage"}>
                <img src={funnyImage} alt=""/>
            </a>
        </>
    )
}
