import {HeaderNav} from "./HeaderNav";
import {Layout} from "antd";
import {Outlet} from "react-router-dom";

const {Header, Content, Footer} = Layout;

export const AppLayout = () => {
    return (
        <>
            <Header>
                <HeaderNav></HeaderNav>
            </Header>
            <Content>
                <Outlet />
            </Content>
            <Footer
                style={{
                    textAlign: 'center',
                }}
            >
                by nikoreos
            </Footer>
        </>
    )
}
