import {Menu, Typography} from "antd";
import {useNavigate} from "react-router-dom";
import {useState} from "react";

import "./headerNav.css";

export const HeaderNav = () => {
    const navigate = useNavigate();
    const [current, setCurrent] = useState('cal');
    const onClick = (e) => {
        setCurrent(e.key);
        switch (e.key) {
            case "cal":
                navigate("/");
                break;
            case "dCom":
                navigate("/drunkcompany");
                break;
            case "mcB":
                navigate("/mcbuildcontest");
                break;
            default:
                break;
        }
    }

    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
        }}>
            <Typography className={"title-text"}>Schabernack Schoktober</Typography>
            <Menu
                theme="dark"
                mode="horizontal"
                items={[
                    {
                        key: "cal",
                        label: 'Kalender'
                    },
                    {
                        key: "events",
                        label: "Events",
                        children: [
                            {
                                key: "dCom",
                                label: "Drunk Company"
                            },
                            {
                                key: "mcB",
                                label: "MC Build Contest"
                            }
                        ]
                    }
                ]}
                style={{
                    flex: 1,
                    minWidth: 0,
                }}
                // selectedKeys={[current]}
                onClick={onClick}
                selectable={false}
            />
        </div>

    )
}
