import {Col, Divider, Row, Typography} from 'antd';

import funnyImage from "../assets/mcBackground.png";
import "../shared/events.css";
import "./mcBuildContest.css";


const {Title, Paragraph, Text, Link} = Typography;

export const MCBuildContest = () => {
    return (
        <>
            <div className="container-mc text">
                <div className={"foreground"}>
                    <Typography>
                        <Title>Minecraft Build Contest</Title>
                        <Paragraph>Der Contest wird auf meinem Server (mc.nikoreos.net) stattfinden. Hier wird eine Fläche von 100x100
                            Blöcken auf dem Creative Server bereitgestellt.<br/>
                            Wer innerhalb der Zeit das Beste Adventure/ Puzzle/ JnR baut, bekommt einen Preis.</Paragraph>

                    </Typography>
                    <Row>
                        <Col span={2}>

                        </Col>
                        <Col span={6}>
                            <Typography>
                                <Title level={2} className={"regelnHeader"}>Regeln & Infos</Title>
                            </Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={3}>

                        </Col>
                        <Col span={4}>
                            <Typography>
                                <Title level={4}>Bedingungen</Title>
                                <ul>
                                    <li>Member auf dem Server sein</li>
                                    <li>18 oder älter sein</li>
                                    <li>Steam Account</li>
                                    <li>Kein Arsch sein</li>
                                </ul>
                                <Title level={4}>Bewertet wird u.a.</Title>
                                <ul>
                                    <li>Kreativität</li>
                                    <li>Design</li>
                                    <li>Gameplay</li>
                                </ul>
                            </Typography>
                        </Col>
                        <Col span={6}>
                            <Typography>
                                <Title level={4}>Thematik</Title>
                                <ul>
                                    <li>Es soll grob um das Thema Halloween gehen.</li>
                                    <li>Was genau, ob gruseln oder Kürbisschnitzen ist freigestellt</li>
                                    <li>Es sollte schon ein paar Minuten länge haben, aber nicht mehr als 30min</li>
                                    <li>Commandblocks sowie andere Plugins stehen <b>nicht</b> zur Verfügung</li>
                                </ul>
                                <Title level={4}>Wie komme ich da hin?</Title>
                                <ul>
                                    <li>Verbinde dich auf mc.nikoreos.net</li>
                                    <li>/server Creative um auf den Creative Server zu kommen</li>
                                    <li>/warp BuildContest um zu den Plots zu kommen</li>
                                </ul>
                            </Typography>
                        </Col>
                        <Col span={6}>
                            <Typography>
                                <Title level={4}>Preis</Title>
                                <ul>
                                    <li>15€ Steam Guthaben</li>
                                    <li>Wird ausgegeben on Stream nach Ende des Contest, sobald ich die Person auf Steam als Freund habe</li>
                                    <li>Sollte nach einer Woche keiner bei mir auf Steam gemeldet haben, wird der Preis an den nächst Platzierten vergeben</li>
                                </ul>
                                <Title level={4}>Auch noch gesagt sei</Title>
                                <ul>
                                    <li>Ich mache das in meiner Freizeit</li>
                                    <li>Ich bewerte subjektiv</li>
                                    <li>Wer mad wird, hat eh keine Chance</li>
                                    <li>Wenns gut läuft, mach ich das öfter</li>
                                </ul>
                            </Typography>
                        </Col>
                        <Col span={5}>

                        </Col>
                    </Row>
                </div>
            </div>
            {/*<div className={"funnyImage"}>*/}
            {/*    <img src={funnyImage} alt=""/>*/}
            {/*</div>*/}
        </>
    )
}
